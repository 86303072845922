// @flow
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'gatsby';
import { useCategoriesList } from '../../../hooks';
import styles from './CategoryList.module.scss';


const CategoryList = () => {
  const categories = useCategoriesList();
    return (
      <div className={styles['category']}>
        <ul className={styles['category__list']}>
          {categories.map((category) => (            
            <li className={styles['category__list-item']} key={category.fieldValue}>
              <Link className={styles['category__list-item-link']} to={`/category/${kebabCase(category.fieldValue)}/`} >
                {category.fieldValue}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
};

export default CategoryList;
