// @flow
import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'gatsby';
import { useTagsList } from '../../../hooks';
import styles from './TagList.module.scss';


const TagList = () => {
  const tags = useTagsList();
    return (
      <div className={styles['tag']}>
        <ul className={styles['tag__list']}>
          {tags.map((tag) => (            
            <li className={styles['tag__list-item']} key={tag.fieldValue}>
              <Link className={styles['tag__list-item-link']} to={`/tag/${kebabCase(tag.fieldValue)}/`} >
                {tag.fieldValue}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
};

export default TagList;
